<template>
  <div id="table-criteria">
    <el-col :span="24" v-loading="!loading">
      <div style="overflow-x: auto">
        <table style="width: 100%" v-if="loading">
          <tr>
            <th rowspan="2">คุณสมบัติ</th>
            <th :colspan="dataGrades.length + 2">
              <!-- {{ dataRes.criterionYear }} -->
              เกรดสินเชื่อ
            </th>
          </tr>
          <tr>
            <th
              class="grade-coll"
              v-for="(grade, index) in dataGrades"
              :key="index"
              style="max-width: 100px; min-width: 100px"
              :style="
                dataRes.active == true
                  ? 'cursor: default;pointer-events: none;'
                  : ''
              "
              v-if="grade.gradeName != 'Non-Prospect'"
            >
              <el-tooltip placement="top" v-if="!statusView">
                <div slot="content">{{ grade.gradeName }}</div>
                <el-dropdown trigger="click">
                  <span
                    class="el-dropdown-link color-ff cursor-pointer"
                    :style="
                      grade.gradeName == 'Non-Prospect'
                        ? 'pointer-events: none;'
                        : ''
                    "
                  >
                    {{ limitString(grade.gradeName) }}
                  </span>
                  <el-dropdown-menu slot="dropdown" class="gread-dropdown">
                    <el-dropdown-item>
                      <div
                        class="box"
                        @click="manageGrade(grade, 'edit')"
                        :id="`editNameGrade` + (index + 1)"
                      >
                        <i class="fas fa-edit"></i> แก้ไขชื่อเกรด
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div
                        class="box"
                        @click="manageGrade(grade, 'delete')"
                        :id="`deleteGrade` + (index + 1)"
                      >
                        <i class="fas fa-trash-alt"></i> ลบเกรด
                      </div></el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </el-tooltip>
              <span class="font-16" v-else>{{
                limitString(grade.gradeName)
              }}</span>
            </th>
            <th
              class="pd-x-6"
              style="width: 63px"
              v-if="
                dataRes.active != true && permissionMore == true && !statusView
              "
            >
              <div @click="setModalAddGrade" class="cursor-pointer">
                <i class="fas fa-plus-circle color-ff"></i>
              </div>
            </th>
            <th
              class="grade-coll"
              v-for="(grade, index) in dataGrades"
              :key="index"
              style="max-width: 100px; min-width: 100px"
              :style="
                dataRes.active == true
                  ? 'cursor: default;pointer-events: none;'
                  : ''
              "
              v-if="grade.gradeName == 'Non-Prospect'"
            >
              <span class="font-16"> Non-Prospect </span>
            </th>
          </tr>
          <!-- เช็ค active ให้แสดงเฉพาะ true -->
          <tr
            v-for="(property, index) in dataRes.property"
            :key="index"
            v-if="property.active && property.propertyNo != 18"
          >
            <td
              class="pd-6"
              style="min-width: 250px"
              v-if="property.propertyNo == 16 || property.propertyNo == 17"
            >
              {{ property.propertyName }}
              <span v-if="property.memberOf.length > 0">{{
                " - " + returnMemBerOf(property.memberOf)
              }}</span>
            </td>
            <td class="pd-6" style="min-width: 250px" v-else>
              {{ property.propertyName }}
            </td>
            <td
              v-for="(grade, index) in property.grade"
              :key="index"
              class="text-center"
              :style="grade.status == false ? 'background: #C7E2FF;' : ''"
              v-if="grade.gradeName != 'Non-Prospect'"
            >
              <i
                class="fas fa-check"
                style="color: #3598ff"
                v-if="grade.status"
              ></i>
            </td>
            <td
              v-if="
                dataRes.active != true && permissionMore == true && !statusView
              "
            ></td>
            <td
              v-for="(grade, index) in property.grade"
              :key="index"
              class="text-center"
              :style="grade.status == false ? 'background: #C7E2FF;' : ''"
              v-if="grade.gradeName == 'Non-Prospect'"
            >
              <i
                class="fas fa-check"
                style="color: #3598ff"
                v-if="grade.status"
              ></i>
            </td>
          </tr>
        </table>
      </div>
    </el-col>
    <el-dialog
      :visible.sync="modalAddGrade"
      width="35%"
      :before-close="closeAddGrade"
      :close-on-click-modal="false"
      class="modal-criterion"
    >
      <el-form
        @submit.native.prevent="submitAddGrade"
        class="set-form"
        label-position="top"
        :model="formAdd"
        :rules="rules"
        ref="formCriterionAdd"
      >
        <el-form-item
          label="เพิ่มเกรดสินเชื่อ"
          prop="gradeTitle"
          class="padding-less font-16"
        >
          <el-input
            v-model="formAdd.gradeTitle"
            @blur="formAdd.gradeTitle = formAdd.gradeTitle.trim()"
            placeholder="กรุณากรอกเกรดสินเชื่อ"
          ></el-input>
        </el-form-item>
        <div class="is-flex js-end mg-t-4">
          <el-button class="font-16 btn-cancle" @click="closeAddGrade"
            >ยกเลิก</el-button
          >
          <el-button
            type="primary"
            class="font-16"
            native-type="submit"
            :loading="loadingSave"
            style="min-width: 120px"
            >บันทึก</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="modalEditGrade"
      width="35%"
      class="modal-criterion"
      :close-on-click-modal="false"
      :before-close="closeEditGrade"
    >
      <el-form
        class="set-form"
        label-position="top"
        :model="formEdit"
        :rules="rules"
        ref="formCriterionEdit"
        @submit.native.prevent="submitEditGrade"
      >
        <el-form-item
          label="แก้ไขชื่อเกรด"
          prop="gradeName"
          class="padding-less font-16"
        >
          <el-input
            v-model="formEdit.gradeName"
            @blur="formEdit.gradeName = formEdit.gradeName.trim()"
            placeholder="กรุณากรอกชื่อเกรด"
          ></el-input>
        </el-form-item>
        <div class="is-flex js-end mg-t-4">
          <el-button class="font-16 btn-cancle" @click="closeEditGrade"
            >ยกเลิก</el-button
          >
          <el-button
            type="primary"
            class="font-16"
            @click="submitEditGrade"
            :loading="loadingSave"
            style="min-width: 120px"
            >บันทึก</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="modalDeleteGrade"
      width="35%"
      class="modal-criterion"
      :close-on-click-modal="false"
      :before-close="closeDeleteGrade"
    >
      <div slot="title">
        <p class="color-00 font-24">คุณต้องการที่จะลบเกรดหรือไม่</p>
      </div>
      <div class="is-flex js-end mg-t-4">
        <el-button class="font-16 btn-cancle" @click="closeDeleteGrade"
          >ไม่ต้องการ</el-button
        >
        <el-button
          type="danger"
          class="font-16"
          @click="submitDeleteGrade"
          :loading="loadingSave"
          style="min-width: 120px"
          >ต้องการ</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  props: ["dataRes", "permissionMore", "statusView"],
  watch: {
    dataRes() {
      this.dataGrades = [];
      this.getAllGrades();
    },
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      user: (state) => state.user,
      memberOfOrganize: (state) => state.memberOfOrganize,
    }),
  },
  data() {
    return {
      loadingSave: false,
      idData: this.$route.params.id,
      dataGrades: [],
      formEdit: {
        gradeId: "",
        gradeName: "",
      },
      formAdd: {
        gradeTitle: "",
      },
      loading: false,
      modalAddGrade: false,
      modalEditGrade: false,
      modalDeleteGrade: false,
      rules: {
        gradeName: [
          {
            required: true,
            message: "กรุณากรอกชื่อเกรด",
            trigger: ["blur", "change"],
          },
        ],
        gradeTitle: [
          {
            required: true,
            message: "กรุณากรอกเกรดสินเชื่อ",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getAllGrades();
  },
  methods: {
    limitString(name) {
      let result = "";
      if (name.length > 15) {
        result = name.substring(0, 13);
        result += "...";
      } else {
        result = name;
      }
      return result;
    },
    async setModalAddGrade() {
      this.modalAddGrade = true;
      await this.$emit("checkIsEdit");
    },
    getAllGrades() {
      for (let y = 0; y < this.dataRes.property[0].grade.length; y++) {
        let obj = {
          gradeId: this.dataRes.property[0].grade[y].gradeId,
          gradeName: this.dataRes.property[0].grade[y].gradeName,
        };
        this.dataGrades.push(obj);
      }
      this.loading = true;
    },

    ckeckStatus(pId, gId) {
      if (this.dataRes.property.length != 0) {
        for (let i = 0; i < this.dataRes.property.length; i++) {
          if (this.dataRes.property[i]._id == pId) {
            for (let y = 0; y < this.dataRes.property[i].grade.length; y++) {
              if (this.dataRes.property[i].grade[y]._id == gId) {
                return this.dataRes.property[i].grade[y].property_grade.status;
              }
            }
          }
        }
      }
    },
    async manageGrade(arr, type) {
      this.formEdit.gradeName = arr.gradeName;
      this.formEdit.gradeId = arr.gradeId;
      if (type == "edit") {
        this.modalEditGrade = true;
      } else if (type == "delete") {
        this.modalDeleteGrade = true;
      }
      await this.$emit("checkIsEdit");
    },
    submitEditGrade() {
      this.$refs["formCriterionEdit"].validate().then((valid) => {
        if (valid) {
          this.loadingSave = true;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          let obj = {
            gradeName: this.formEdit.gradeName,
          };
          HTTP.put(`criterion/grade/${this.formEdit.gradeId}`, obj)
            .then(async (res) => {
              if (res.data.success) {
                await this.$emit("saveEdit");
                this.$emit("fetchData", true);
                this.$message({
                  message: "แก้ไขชื่อเกรด สำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                let debounce = null;
                clearTimeout(debounce);
                debounce = setTimeout(() => {
                  this.loadingSave = false;
                }, 600);
                this.modalEditGrade = false;
              } else {
                this.loadingSave = false;

                if (res.data.status == 204) {
                  this.$message({
                    message: "ชื่อเกรดเชื่อซ้ำ",
                    type: "error",
                    duration: 4000,
                  });
                }
              }
            })
            .catch((e) => {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            });
        }
      });
    },
    submitDeleteGrade() {
      this.loadingSave = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        gradeName: this.formEdit.gradeName,
      };
      HTTP.delete(`criterion/grade/${this.formEdit.gradeId}`, obj)
        .then(async (res) => {
          if (res.data.success) {
            await this.$emit("saveEdit");
            this.$emit("fetchData", true);
            this.$message({
              message: "ลบเกรด สำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.modalDeleteGrade = false;
            let debounce = null;
            clearTimeout(debounce);
            debounce = setTimeout(() => {
              this.loadingSave = false;
            }, 600);
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    async closeAddGrade() {
      this.modalAddGrade = false;
      this.formAdd.gradeTitle = "";
      this.loadingSave = false;
      await this.$emit("destroyedEdit");

      // await destroyedEdit
    },
    submitAddGrade() {
      this.$refs["formCriterionAdd"].validate().then((valid) => {
        if (valid) {
          if (this.formAdd.gradeTitle.toLowerCase() != "non-prospect") {
            this.loadingSave = true;
            HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
            let obj = {
              gradeName: this.formAdd.gradeTitle,
            };
            HTTP.post(`criterion/grade/${this.idData}`, obj)
              .then(async (res) => {
                if (res.data.success) {
                  await this.$emit("saveEdit");
                  this.$emit("fetchData", true);
                  this.$message({
                    message: "เพิ่มเกรดสินเชื่อ สำเร็จ",
                    type: "success",
                    duration: 4000,
                  });
                  this.modalAddGrade = false;
                  this.formAdd.gradeTitle = "";
                  let debounce = null;
                  clearTimeout(debounce);
                  debounce = setTimeout(() => {
                    this.loadingSave = false;
                  }, 600);
                } else {
                  this.loadingSave = false;
                  if (res.data.status == 204) {
                    this.$message({
                      message: "ชื่อเกรดสินเชื่อของคุณซ้ำ",
                      type: "error",
                      duration: 4000,
                    });
                  }
                }
              })
              .catch((e) => {
                if (e.response.data.status == 401) {
                  this.checkAuth();
                } else {
                  this.alertCatchError(e);
                }
              });
          } else {
            this.$message({
              message: "ไม่สามารถตั้งชื่อ Non-Prospect นี้ได้",
              type: "error",
              duration: 4000,
            });
          }
        }
      });
    },
    async closeEditGrade() {
      this.modalEditGrade = false;
      await this.$emit("destroyedEdit");
    },
    async closeDeleteGrade() {
      this.modalDeleteGrade = false;
      await this.$emit("destroyedEdit");
    },
    returnMemBerOf(data) {
      let result = "";
      data.forEach((row, index) => {
        var findByName = this.memberOfOrganize.find((e) => e.value == row);
        if (findByName.value != 6 && findByName.value != 7) {
          result += findByName.shortName;
        } else {
          result += findByName.label;
        }
        if (index != data.length - 1) {
          result += " / ";
        }
      });
      // console.log(result);
      return result;
    },
  },
};
</script>
