<template>
  <div>
    <el-col :span="24" class="mg-b-6">
      <h1>สินเชื่อที่นำเสนอ</h1>
      <el-tabs
        type="card"
        v-model="nameTab"
        class="tab-dashboard size-tab"
        @tab-click="checkSizeTabs"
      >
        <el-tab-pane label="Size S" name="S"></el-tab-pane>
        <el-tab-pane label="Size M" name="M"></el-tab-pane>
        <el-tab-pane label="Size L" name="L"></el-tab-pane>
      </el-tabs>
    </el-col>
    <el-col :span="24">
      <div id="criteria-size" style="overflow-x: auto">
        <div v-loading="loading">
          <table style="width: 100%" v-if="!loading">
            <tr>
              <th>ประเภทสินเชื่อ</th>
              <th
                class="grade-coll"
                v-for="(grade, index) in dataGrades"
                :key="index"
                style="max-width: 100px; min-width: 100px"
                :style="
                  dataRes.active == true
                    ? 'cursor: default;pointer-events: none;'
                    : ''
                "
                v-if="grade.gradeName != 'Non-Prospect'"
              >
                <el-tooltip placement="top">
                  <div slot="content">{{ grade.gradeName }}</div>
                  <!-- <el-dropdown trigger="click"> -->
                  <span class="font-16 color-ff">
                    {{ limitString(grade.gradeName) }}
                  </span>
                  <!-- </el-dropdown> -->
                </el-tooltip>
              </th>
              <th
                class="pd-x-6"
                style="width: 63px"
                v-if="
                  dataRes.active != true &&
                  permissionMore == true &&
                  !statusView
                "
              ></th>
              <th
                class="grade-coll"
                v-for="(grade, index) in dataGrades"
                :key="index"
                style="max-width: 100px; min-width: 100px"
                :style="
                  dataRes.active == true
                    ? 'cursor: default;pointer-events: none;'
                    : ''
                "
                v-if="grade.gradeName == 'Non-Prospect'"
              >
                <span class="font-16"> Non-Prospect </span>
              </th>
            </tr>
            <!-- <tbody v-if="nameTab == 'L'">
              <tr v-for="(SizeL, indexL) in arrCreditSize.sizeL" :key="indexL">
                <td class="pd-6" style="min-width: 250px">
                  <span> {{ SizeL.creditName }}</span>
                </td>
              </tr>
              <tr v-if="arrCreditSize.sizeL.length == 0">
                <td class="pd-6" style="min-width: 250px">
                  <span> - </span>
                </td>
              </tr>
            </tbody> -->
            <tr v-if="arrCreditSize.workingCap.length > 0">
              <td class="pd-6" style="min-width: 250px">Working Capital</td>
              <td
                v-for="(grade, index1) in dataGrades"
                :key="index1"
                style="max-width: 100px; min-width: 100px"
                v-if="grade.gradeName != 'Non-Prospect'"
              >
                <div
                  class="checkForLoop"
                  v-if="
                    checkForLoop(
                      arrCreditSize.workingCap,
                      grade,
                      findGrade[index1]
                    )
                  "
                >
                  <span
                    class="loop-criteria"
                    v-for="(workingCap, indexW) in arrCreditSize.workingCap"
                    :key="indexW"
                    v-if="
                      checkCredit(workingCap, grade, findGrade[index1]) != '-'
                    "
                  >
                    <!-- {{arrCreditSize.workingCap.length +''+ index1}} -->
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="
                        checkCredit(
                          workingCap,
                          grade,
                          findGrade[index1],
                          'tooltip'
                        )
                      "
                      placement="bottom"
                    >
                      <span
                        v-if="
                          arrCreditSize.workingCap.length > 0 &&
                          checkCredit(workingCap, grade, findGrade[index1]) !=
                            '-'
                        "
                      >
                        <!-- <span v-if="indexW != 0 && 
                        checkCredit(workingCap, grade, findGrade[index1]) != '-'" > , </span> -->

                        {{ checkCredit(workingCap, grade, findGrade[index1]) }}

                        <!-- <span v-if="indexW-1 != arrCreditSize.workingCap.length - 1 && indexW != arrCreditSize.workingCap.length - 1"
                          >,
                        </span> -->
                      </span>
                    </el-tooltip>

                    <!-- <span v-if="indexW != arrCreditSize.workingCap.length - 1 "
                      >,
                    </span> -->
                  </span>
                </div>
                <span v-else>-</span>
              </td>
              <td
                class="pd-x-6"
                style="width: 63px; min-width: 13px"
                v-if="
                  dataRes.active != true &&
                  permissionMore == true &&
                  !statusView
                "
              ></td>
              <!-- <td
                v-for="(grade, index1) in dataGrades"
                :key="index1"
                style="max-width: 100px; min-width: 100px"
                v-if="grade.gradeName == 'Non-Prospect'"
              >
                <span>
                  {{
                    checkCredit(
                      arrCreditSize.workingCap,
                      grade,
                      findGrade[index1]
                    )
                  }}</span
                >
              </td> -->
              <td
                v-for="(grade, index1) in dataGrades"
                :key="index1"
                style="max-width: 100px; min-width: 100px"
                v-if="grade.gradeName == 'Non-Prospect'"
              >
                <div
                  class="checkForLoop"
                  v-if="
                    checkForLoop(
                      arrCreditSize.workingCap,
                      grade,
                      findGrade[index1]
                    )
                  "
                >
                  <span
                    class="loop-criteria"
                    v-for="(workingCap, indexW) in arrCreditSize.workingCap"
                    :key="indexW"
                    v-if="
                      checkCredit(workingCap, grade, findGrade[index1]) != '-'
                    "
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="
                        checkCredit(
                          workingCap,
                          grade,
                          findGrade[index1],
                          'tooltip'
                        )
                      "
                      placement="bottom"
                    >
                      <span
                        v-if="
                          arrCreditSize.workingCap.length > 0 &&
                          checkCredit(workingCap, grade, findGrade[index1]) !=
                            '-'
                        "
                      >
                        {{ checkCredit(workingCap, grade, findGrade[index1]) }}
                      </span>
                    </el-tooltip>
                  </span>
                </div>
                <span v-else>-</span>
              </td>
            </tr>
            <tr v-else-if="arrCreditSize.workingCap.length == 0">
              <td class="pd-6" style="min-width: 250px">Working Capital</td>
              <td
                v-for="(grade, index1) in dataGrades"
                :key="index1"
                style="max-width: 100px; min-width: 100px"
                v-if="grade.gradeName != 'Non-Prospect'"
              >
                -
              </td>
              <td
                class="pd-x-6"
                style="width: 63px; min-width: 13px"
                v-if="
                  dataRes.active != true &&
                  permissionMore == true &&
                  !statusView
                "
              ></td>
              <td
                v-for="(grade, index1) in dataGrades"
                :key="index1"
                v-if="grade.gradeName == 'Non-Prospect'"
                style="max-width: 100px; min-width: 100px"
              >
                -
              </td>
            </tr>
            <tr v-if="arrCreditSize.loan.length > 0">
              <td class="pd-6" style="min-width: 250px">
                Loan (สินเชื่อระยะกลาง-ยาว)
              </td>
              <td
                v-for="(grade, index1) in dataGrades"
                :key="index1"
                style="max-width: 100px; min-width: 100px"
                v-if="grade.gradeName != 'Non-Prospect'"
              >
                <!-- <span
                  v-for="(loan, indexL) in arrCreditSize.loan"
                  :key="indexL"
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      checkCredit(loan, grade, findGrade[index1], 'tooltip')
                    "
                    placement="bottom"
                  >
                    <span
                      v-if="
                        arrCreditSize.loan.length > 0 &&
                        checkCredit(loan, grade, findGrade[index1]) != '-'
                      "
                    >
                      <span v-if="indexL != 0"> , </span>
                      <span>
                        {{ checkCredit(loan, grade, findGrade[index1]) }}</span
                      >
                    </span>
                  </el-tooltip>
                  <span
                    v-if="
                      indexL == 0 &&
                      checkCredit(loan, grade, findGrade[index1]) == '-'
                    "
                    >-</span
                  >
                </span> -->
                <!-- <span v-if="indexL != arrCreditSize.loan.length - 1">, </span> -->
                <div
                  v-if="
                    checkForLoop(arrCreditSize.loan, grade, findGrade[index1])
                  "
                  class="checkForLoop"
                >
                  <span
                    class="loop-criteria"
                    v-for="(loan, indexW) in arrCreditSize.loan"
                    :key="indexW"
                    v-if="checkCredit(loan, grade, findGrade[index1]) != '-'"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="
                        checkCredit(loan, grade, findGrade[index1], 'tooltip')
                      "
                      placement="bottom"
                    >
                      <span
                        v-if="
                          arrCreditSize.loan.length > 0 &&
                          checkCredit(loan, grade, findGrade[index1]) != '-'
                        "
                      >
                        <!-- <span v-if="indexW != 0 && 
                        checkCredit(loan, grade, findGrade[index1]) != '-'" > , </span> -->

                        {{ checkCredit(loan, grade, findGrade[index1]) }}
                        <!-- <span v-if="indexW-1 != arrCreditSize.loan.length - 1 && indexW != arrCreditSize.loan.length - 1"
                          >,
                        </span> -->
                      </span>
                    </el-tooltip>
                  </span>
                </div>
                <span v-else>-</span>
              </td>
              <td
                class="pd-x-6"
                style="width: 63px; min-width: 13px"
                v-if="
                  dataRes.active != true &&
                  permissionMore == true &&
                  !statusView
                "
              ></td>
              <td
                v-for="(grade, index1) in dataGrades"
                :key="index1"
                style="max-width: 100px; min-width: 100px"
                v-if="grade.gradeName == 'Non-Prospect'"
              >
                <div
                  v-if="
                    checkForLoop(arrCreditSize.loan, grade, findGrade[index1])
                  "
                  class="checkForLoop"
                >
                  <span
                    class="loop-criteria"
                    v-for="(loan, indexW) in arrCreditSize.loan"
                    :key="indexW"
                    v-if="checkCredit(loan, grade, findGrade[index1]) != '-'"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="
                        checkCredit(loan, grade, findGrade[index1], 'tooltip')
                      "
                      placement="bottom"
                    >
                      <span
                        v-if="
                          arrCreditSize.loan.length > 0 &&
                          checkCredit(loan, grade, findGrade[index1]) != '-'
                        "
                      >
                        <!-- <span v-if="indexW != 0 && 
                        checkCredit(loan, grade, findGrade[index1]) != '-'" > , </span> -->

                        {{ checkCredit(loan, grade, findGrade[index1]) }}
                        <!-- <span v-if="indexW-1 != arrCreditSize.loan.length - 1 && indexW != arrCreditSize.loan.length - 1"
                          >,
                        </span> -->
                      </span>
                    </el-tooltip>
                  </span>
                </div>
                <span v-else>-</span>
              </td>
            </tr>
            <tr v-else-if="arrCreditSize.loan.length == 0">
              <td class="pd-6">Loan (สินเชื่อระยะกลาง-ยาว)</td>
              <td
                v-for="(grade, index1) in dataGrades"
                :key="index1"
                style="max-width: 100px; min-width: 100px"
                v-if="grade.gradeName != 'Non-Prospect'"
              >
                -
              </td>
              <td
                class="pd-x-6"
                style="width: 63px; min-width: 13px"
                v-if="
                  dataRes.active != true &&
                  permissionMore == true &&
                  !statusView
                "
              ></td>
              <td
                v-for="(grade, index1) in dataGrades"
                :key="index1"
                style="max-width: 100px; min-width: 100px"
                v-if="grade.gradeName == 'Non-Prospect'"
              >
                -
              </td>
            </tr>
          </table>
        </div>
      </div>
    </el-col>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";

export default {
  props: ["idData", "dataRes", "permissionMore", "statusView"],
  watch: {
    dataRes() {
      this.dataGrades = [];
      this.getCredit();
      this.getAllGrades();
      this.findValueGrades();
    },
  },
  data() {
    return {
      nameTab: "S",
      dataCredit: [],
      dataGrades: [],
      loading: true,
      arrCreditSize: [],
      findGrade: [],
    };
  },
  computed: {
    _token() {
      return this.$store.state._token;
    },
  },
  mounted() {
    this.getCredit();
    this.getAllGrades();
    this.findValueGrades();
  },
  methods: {
    limitString(name) {
      let result = "";
      if (name.length > 15) {
        result = name.substring(0, 13);
        result += "...";
      } else {
        result = name;
      }
      return result;
    },
    getAllGrades() {
      for (let y = 0; y < this.dataRes.property[0].grade.length; y++) {
        let obj = {
          gradeId: this.dataRes.property[0].grade[y].gradeId,
          gradeName: this.dataRes.property[0].grade[y].gradeName,
        };
        this.dataGrades.push(obj);
      }
    },
    async findValueGrades() {
      let pptArr = [];
      await this.dataRes.property.forEach(async (ppt) => {
        await ppt.grade.forEach((g) => {
          pptArr.push(g);
        });
      });
      this.findGrade = await this.dataGrades.map((row) => {
        return {
          gradeName: row.gradeName,
          count: pptArr.filter(
            (e) => e.gradeName === row.gradeName
            // e.gradeName === row.gradeName && e.status === true
          ).length,
        };
      });
    },
    getCredit() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`criterion/credit/${this.idData}`)
        .then((res) => {
          if (res.data.success) {
            this.dataCredit = res.data.obj;
            this.checkSizeTabs();
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    checkSizeTabs() {
      this.loading = true;
      if (this.nameTab == "S") {
        this.arrCreditSize.workingCap = this.dataCredit.creditSizeS.filter(
          (a) =>
            a.interestType == 1 &&
            a.grade.length > 0 &&
            a.hiddenCredit == true &&
            a.sendToCH == false
        );
        this.arrCreditSize.loan = this.dataCredit.creditSizeS.filter(
          (a) =>
            a.interestType == 2 &&
            a.grade.length > 0 &&
            a.hiddenCredit == true &&
            a.sendToCH == false
        );
        // console.log("workingCap",this.arrCreditSize.workingCap)
        // console.log("loan",this.arrCreditSize.loan)
      } else if (this.nameTab == "M") {
        this.arrCreditSize.workingCap = this.dataCredit.creditSizeM.filter(
          (a) =>
            a.interestType == 1 &&
            a.grade.length > 0 &&
            a.hiddenCredit == true &&
            a.sendToCH == false
        );
        this.arrCreditSize.loan = this.dataCredit.creditSizeM.filter(
          (a) =>
            a.interestType == 2 &&
            a.grade.length > 0 &&
            a.hiddenCredit == true &&
            a.sendToCH == false
        );
      } else if (this.nameTab == "L") {
        this.arrCreditSize.workingCap = this.dataCredit.creditSizeL.filter(
          (a) =>
            a.interestType == 1 &&
            a.grade.length > 0 &&
            a.hiddenCredit == true &&
            a.sendToCH == false
        );
        this.arrCreditSize.loan = this.dataCredit.creditSizeL.filter(
          (a) =>
            a.interestType == 2 &&
            a.grade.length > 0 &&
            a.hiddenCredit == true &&
            a.sendToCH == false
        );
        // this.arrCreditSize.sizeL = this.dataCredit.creditSizeL;
        // this.arrCreditSize.workingCap = [];
        // this.arrCreditSize.loan = [];
      }

      this.loading = false;
    },
    checkCredit(data, grade, findArr, type) {
      let getGrade = grade;
      let a = "";
      let returnTooltip = "";
      if (typeof findArr != "undefined") {
        // if (findArr.gradeName == getGrade.gradeName && findArr.count > 0) {
        //   for (let i = 0; i < data.length; i++) {
        //     console.log(data)
        //     for (let y = 0; y < data[i].grade.length; y++) {
        //       if (getGrade.gradeName == data[i].grade[y].gradeName) {
        //         if (a != "") {
        //           a += " ,";
        //         }
        //         a += data[i].creditProductId;
        //       }
        //     }
        //   }
        // }
        if (findArr.gradeName == getGrade.gradeName && findArr.count > 0) {
          if (typeof data.grade != "undefined") {
            for (let y = 0; y < data.grade.length; y++) {
              if (getGrade.gradeName == data.grade[y].gradeName) {
                a += data.productProgramName;
                if (type == "tooltip") {
                  return (returnTooltip += data.creditName);
                }
              }
            }
          }
        }
      }
      if (a == "") {
        return "-";
      } else {
        return a;
      }
    },
    checkForLoop(data, grade, findArr) {
      let getGrade = grade;
      let a = 0;
      for (let i = 0; i < data.length; i++) {
        if (findArr.gradeName == getGrade.gradeName && findArr.count > 0) {
          for (let y = 0; y < data[i].grade.length; y++) {
            if (getGrade.gradeName == data[i].grade[y].gradeName) {
              a = 1;
            }
          }
        }
      }
      if (a == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
