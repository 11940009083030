<template>
  <DefaultTemplate
    id="criteria-credit"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        class="set-breadcrumb"
      >
        <el-breadcrumb-item :to="{ path: '/criteria-credit' }"
          >เกณฑ์พิจารณาจัดเกรดสินเชื่อ</el-breadcrumb-item
        >
        <el-breadcrumb-item>
          <span>{{ dataRes.criterionName }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="box-name-page criteria" v-if="!loading">
        <p class="title mg-t-4">
          <span class="mg-r-6">{{ dataRes.criterionName }}</span>
          <el-button
            class="btn-color2"
            v-if="
              dataRes.active != true &&
              checkPermissionMore() == true &&
              dataRes.statusWaite != 1
            "
            @click="openModalEditName"
          >
            <i class="fas fa-edit font-14 color-ff"></i>
          </el-button>
          <span
            v-if="dataRes.statusWaite == 1"
            class="font-18"
            style="color: #757575"
          >
            <i class="el-icon-loading"></i>
            <span class="pd-x-7">{{
              dataRes.waiteBy.empFname + " " + dataRes.waiteBy.empLname
            }}</span>
            กำลังแก้ไข
          </span>
          <span v-else class="font-18 mg-l-6" style="color: #757575">
            <span class="pd-x-7" v-if="dataRes.editBy != null"
              >อัปเดตโดย
              {{ dataRes.editBy.empFname + " " + dataRes.editBy.empLname }}
              เมื่อ {{ dataRes.editedDate | formatDateTHFull }} น.</span
            >
          </span>
        </p>

        <div class="is-flex ai-center" v-if="checkPermissionMore()">
          <el-button
            class="bg-ed"
            :class="dataRes.statusWaite == 1 ? 'btn-disabled' : ''"
            @click="openDrawerEdit"
            v-if="dataRes.active != true"
          >
            <i class="fas fa-edit"></i> แก้ไขเกณฑ์สินเชื่อ
          </el-button>

          <el-button class="mg-r-5 btn-round color-c4" @click="getHistoryLog">
            <i class="fas fa-history"></i>
          </el-button>
        </div>
      </div>
      <el-row :gutter="20" v-if="!loading">
        <TableGrade
          :dataRes="dataRes"
          :permissionMore="checkPermissionMore()"
          :statusView="dataRes.statusWaite"
          @fetchData="fetchDataOut"
          @checkIsEdit="checkIsEdit"
          @destroyedEdit="destroyedEdit"
          @saveEdit="sendIsEdit(0, 2)"
        />
        <TabNameSize
          :dataRes="dataRes"
          :idData="idData"
          :permissionMore="checkPermissionMore()"
          :statusView="dataRes.statusWaite"
        />
      </el-row>
    </div>
    <el-dialog
      v-if="modalEditName"
      :visible.sync="modalEditName"
      width="30%"
      class="modal-criterion"
      :close-on-click-modal="false"
      @close="handleClose('modalEditName')"
    >
      <div slot="title">
        <p class="text-center color-00 font-22">
          แก้ไขชื่อเกณฑ์พิจารณาจัดเกรดสินเชื่อ
        </p>
      </div>
      <el-form
        class="set-form"
        label-position="top"
        :model="formEdit"
        :rules="rules"
        ref="formCriterionEdit"
      >
        <el-form-item
          label="ชื่อตาราง"
          prop="criterionName"
          class="padding-less font-16"
        >
          <el-input
            id="criterionNameEdit"
            v-model="formEdit.criterionName"
            placeholder="ชื่อตาราง"
            @blur="trimInputValue(formEdit.criterionName)"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="วันที่สร้างเกณฑ์"
          prop="criterionDate"
          class="padding-less font-16"
        >
          <el-input
            id="criterionDate"
            v-model="criterionDate"
            readonly
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          label="ปี พ.ศ."
          prop="criterionYear"
          class="padding-less font-16"
        >
          <el-select
            id="criterionYearEdit"
            v-model="formEdit.criterionYear"
            placeholder="เพิ่มพ.ศ."
          >
            <el-option
              v-for="item in optionYears"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div class="is-flex js-end mg-t-4">
        <el-button
          id="cancleEditCriterion"
          class="font-16 btn-cancle"
          @click="handleClose('modalEditName')"
          >ยกเลิก</el-button
        >
        <el-button
          id="submitEditCriterion"
          type="primary"
          class="font-16"
          @click="submitEditCriterion"
          style="min-width: 120px"
          >บันทึก</el-button
        >
      </div>
    </el-dialog>
    <el-drawer
      class="drawer-criterion"
      :visible.sync="drawerEdit"
      :with-header="false"
      :before-close="drawerClose"
      :close-on-click-modal="false"
    >
      <div class="box-header">
        <div class="text-center" style="position: relative">
          <span> แก้ไขเกณฑ์สินเชื่อ </span>
          <span class="icon-close" @click="drawerClose"> X </span>
        </div>
      </div>
      <div id="custom-collapse">
        <div
          v-for="(property, index) in tableEditGrades.property"
          :key="index"
          class="box-collapse"
          :class="property.propertyNo == 18 ? 'disabled' : ''"
        >
          <span>
            <el-checkbox
              :indeterminate="
                property.propertyNo == 18 ? false : property.isIndeterminate
              "
              v-model="property.propertyNo == 18 ? false : property.active"
              :disabled="property.propertyNo == 18 ? true : false"
              @change="handleCheckAllChange(index, 'normal')"
            >
            </el-checkbox>
            <span v-if="property.statusEdit">
              <span v-if="property.propertyNo == 3">
                ยอดขายปีล่าสุดลดลงไม่เกิน
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                %
              </span>
              <span v-else-if="property.propertyNo == 4">
                กำไร
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                ใน
                <vue-numeric
                  :id="`setValue${index}${index}`"
                  v-model="property.val2"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                ปี
              </span>
              <span v-else-if="property.propertyNo == 5">
                กำไร
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                ใน
                <vue-numeric
                  :id="`setValue${index}${index}`"
                  v-model="property.val2"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                ปี
              </span>
              <span v-else-if="property.propertyNo == 6">
                Current Ratio ไม่ต่ำกว่า
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                เท่า
              </span>
              <span v-else-if="property.propertyNo == 9" class="color-danger">
                D/E Ratio ไม่เกิน
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                เท่า
              </span>
              <span v-else-if="property.propertyNo == 10">
                DSCR >
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
              </span>
              <span v-else-if="property.propertyNo == 11">
                รายได้จากการดำเนินการ
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7 min-100"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                -
                <vue-numeric
                  :id="`setValue${index}${index}`"
                  v-model="property.val2"
                  class="mg-x-7 min-100"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                บาท
              </span>
              <span v-else-if="property.propertyNo == 12">
                กำไรสุทธิ
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                -
                <vue-numeric
                  :id="`setValue${index}${index}`"
                  v-model="property.val2"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                บาท
              </span>
              <span v-else-if="property.propertyNo == 13">
                กำไร
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                ใน
                <vue-numeric
                  :id="`setValue${index}${index}`"
                  v-model="property.val2"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                ปี
              </span>
              <span v-else-if="property.propertyNo == 14">
                กำไร
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                ใน
                <vue-numeric
                  :id="`setValue${index}${index}`"
                  v-model="property.val2"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                ปี
              </span>
              <span v-else-if="property.propertyNo == 15">
                กำไร
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                ใน
                <vue-numeric
                  :id="`setValue${index}${index}`"
                  v-model="property.val2"
                  class="mg-x-7 min-50"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                ปี
              </span>
              <!-- <span v-else-if="index == 8">
                อัตราส่วนความสามารถในการชำระดอกเบี้ย >
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7"
                  @keypress="isNumber($event)"
                ></vue-numeric>
              </span>
              <span v-else-if="index == 9">
                รายได้
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                -
                <vue-numeric
                  :id="`setValue${index}${index}`"
                  v-model="property.val2"
                  class="mg-x-7"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                บาท
              </span>

              // 3 ตัวล่างสุดที่ปิด disabled ไว้
              <span v-else-if="index == 10">
                กำไรสุทธิ
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7"
                  @keypress="isNumber($event)"
                ></vue-numeric>
                บาท
              </span> -->
              <!-- <span v-else-if="index == 9">
                Credit Scoring NCB สูงกว่า
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7"
                  @keypress="isNumber($event)"
                ></vue-numeric>
              </span>
              <span v-else-if="index == 10">
                Credit Scoring บสย. สูงกว่า
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7"
                  @keypress="isNumber($event)"
                ></vue-numeric>
              </span>
              <span v-else-if="index == 11">
                Credit Scoring ธสน. สูงกว่า
                <vue-numeric
                  :id="`setValue${index}`"
                  v-model="property.val1"
                  class="mg-x-7"
                  @keypress="isNumber($event)"
                ></vue-numeric>
              </span> -->
              <span v-else-if="property.propertyNo == 16">
                {{ property.propertyName }}
              </span>
              <span v-else-if="property.propertyNo == 17">
                {{ property.propertyName }}
              </span>
            </span>
            <span
              v-else
              :class="
                property.propertyNo == 7 || property.propertyNo == 9
                  ? 'color-red-soft'
                  : ''
              "
            >
              {{ property.propertyName }}
            </span>
          </span>

          <span class="collapsible" style="" v-if="property.propertyNo != 18">
            <span
              class="mg-r-6"
              v-if="property.val1 !== '' && property.val2 !== ''"
            >
              <el-button
                class="btn-color1"
                v-if="
                  (property.statusEdit && property.propertyNo == 16) ||
                  (property.statusEdit && property.propertyNo == 17)
                "
                :loading="loadingSaveProperty"
                @click="editMemberOf(property)"
              >
                <i class="fas fa-save color-ff font-14"></i>
              </el-button>
              <el-button
                class="btn-color1"
                v-else-if="property.statusEdit"
                :loading="loadingSaveProperty"
                @click="editListProperty(index, 'save')"
              >
                <i class="fas fa-save color-ff font-14"></i>
              </el-button>
              <el-button
                class="btn-color2"
                v-else
                @click="editListProperty(index), (property.statusEdit = true)"
              >
                <i class="fas fa-edit color-ff font-14"></i>
              </el-button>
            </span>
            <i
              class="fas fa-chevron-right"
              @click="openContent(index, 'property')"
            ></i>
          </span>

          <div
            v-if="property.propertyNo == 16 || property.propertyNo == 17"
            class="pd-l-5"
          >
            <el-select
              class="selectMemberOf"
              multiple
              clearable
              default-first-option
              v-model="property.memberOf"
              placeholder="เลือกหน่วยงาน"
              popper-class="custom-select"
              :disabled="property.statusEdit == true ? false : true"
            >
              <el-option
                v-for="(item, index) in memberOfOrganize"
                :key="index"
                :value="item.value"
                :label="
                  typeof item.shortName != 'undefined'
                    ? item.shortName
                    : item.label
                "
                :disabled="disabledMemberOf(property.memberOf, item.value)"
              >
                <div class="is-flex ai-center">
                  <div class="circle-select"></div>
                  {{
                    typeof item.shortName != "undefined"
                      ? item.shortName
                      : item.label
                  }}
                </div>
              </el-option>
            </el-select>
          </div>
          <div class="content">
            <div class="mg-y-5 mg-b-7">
              <el-row :gutter="20">
                <el-col
                  :span="8"
                  v-for="(grade, indexg) in property.grade"
                  :key="indexg"
                  v-if="grade.gradeName != 'Non-Prospect'"
                >
                  <el-checkbox-group
                    v-model="property.checkedList"
                    @change="
                      handleCheckedAllGrade(
                        property.checkedList,
                        index,
                        'normal'
                      )
                    "
                  >
                    <el-checkbox
                      class="limit-string"
                      :label="grade.gradeName"
                      v-model="grade.status"
                      @change="
                        checkAllGrade(index), (grade.status = !grade.status)
                      "
                    >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="grade.gradeName"
                        placement="bottom"
                      >
                        <span>
                          {{ grade.gradeName }}
                        </span>
                      </el-tooltip>
                    </el-checkbox>
                  </el-checkbox-group>
                </el-col>
                <el-col :span="8">
                  <el-checkbox
                    label="Non-Prospect"
                    :disabled="true"
                  ></el-checkbox>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <el-collapse
          accordion
          class="condition-tab mg-t-4"
          v-model="activeName"
          @change="handleChange"
        >
          <el-collapse-item name="special">
            <template slot="title">
              <span> ตั้งค่าคุณสมบัติเกณฑ์แบบพิเศษ </span>
            </template>
            <div v-loading="loadingEdit">
              <div v-if="showAddCondotion">
                <div
                  v-for="(special, index) in tableEditGrades.propertySpecial"
                  :key="index"
                  class="box-collapse pd-0"
                >
                  <span>
                    <el-checkbox
                      :indeterminate="special.isIndeterminate"
                      v-model="special.active"
                      @change="handleCheckAllChange(index, 'special')"
                    >
                    </el-checkbox>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="special.propertyName"
                      placement="bottom"
                    >
                      <span>
                        {{ special.propertyName }}
                      </span>
                    </el-tooltip>
                  </span>
                  <span class="collapsible1" style="">
                    <el-button
                      class="btn-color2"
                      @click="editPropertySpecial(special)"
                    >
                      <i class="fas fa-edit font-14"> </i>
                    </el-button>

                    <el-button
                      class="btn-color3 mg-r-6 mg-l-7"
                      @click="modalSpecial(special)"
                    >
                      <i class="fas fa-trash-alt font-14"></i>
                    </el-button>
                    <i
                      class="fas fa-chevron-right"
                      @click="openContent(index, 'special')"
                    ></i>
                  </span>
                  <div class="content1" style="width: 474px">
                    <div class="mg-y-5 mg-b-7">
                      <el-row :gutter="20">
                        <el-col
                          :span="8"
                          v-for="(grade, indexGP) in special.grade"
                          :key="indexGP"
                        >
                          <el-checkbox-group
                            v-model="special.checkedList"
                            @change="
                              handleCheckedAllGrade(
                                special.checkedList,
                                index,
                                'special'
                              )
                            "
                            ><el-checkbox
                              class="limit-string"
                              :label="grade.gradeName"
                              v-model="grade.status"
                              @change="
                                checkAllGrade(index),
                                  (grade.status = !grade.status)
                              "
                            >
                              <el-tooltip
                                class="item"
                                effect="dark"
                                :content="grade.gradeName"
                                placement="bottom"
                              >
                                <span>
                                  {{ grade.gradeName }}
                                </span>
                              </el-tooltip>
                            </el-checkbox>
                          </el-checkbox-group>

                          <!-- <el-checkbox
                            :label="grade.gradeName"
                            v-model="grade.status"
                            @change="checkAllGrade(index, 'special')"
                          ></el-checkbox> -->
                        </el-col>
                        <el-col :span="8">
                          <el-checkbox
                            label="Non-Prospect"
                            :disabled="true"
                          ></el-checkbox>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </div>
                <div class="text-center mg-t-6">
                  <button
                    id="addSpecialCondotion"
                    class="btn-round-condotion font-20"
                    @click="addSpecial"
                  >
                    <i class="fas fa-plus"></i> เพิ่มคุณสมบัติเกณฑ์แบบพิเศษ
                  </button>
                </div>
              </div>
              <div id="box-condition-special" v-if="conditionTab">
                <el-form
                  :model="conditionForm"
                  :rules="rules"
                  label-position="top"
                  ref="conditionForm1"
                  label-width="120px"
                  class="set-form-condition"
                >
                  <el-row :gutter="30">
                    <el-col :span="24" v-if="showEditCondotion" class="mg-t-5">
                      <div class="is-flex js-between ai-center">
                        <span class="font-weight-bold">{{
                          propertyNameById
                        }}</span>
                        <el-button
                          id="UpdatePropertySpecial"
                          class="btn-color1"
                          @click="UpdatePropertySpecial"
                        >
                          <i class="fas fa-save color-ff font-14"></i>
                        </el-button>
                      </div>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item
                        class="padding-less custom-form"
                        style="text-align: initial"
                        prop="propertyName"
                      >
                        <template slot="label" class="is-flex">
                          <span class="select is-required">
                            ชื่อคุณสมบัติเกณฑ์
                          </span>
                          <el-button
                            class="btn-color1"
                            v-if="!showEditCondotion"
                            @click="savePropertySpecial"
                          >
                            <i class="fas fa-save color-ff font-14"></i>
                          </el-button>
                        </template>
                        <el-input
                          id="conditionPropertyName"
                          v-model="conditionForm.propertyName"
                          placeholder="กรุณากรอกชื่อคุณสมบัติเกณฑ์"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="24"
                      class="mg-t-5"
                      v-for="(conditions, indexf) in conditionForm.conditions"
                      :key="indexf"
                    >
                      <div class="box-condition">
                        <el-row :gutter="20">
                          <el-col :span="6" v-if="indexf > 0">
                            <el-form-item
                              label="ฟังก์ชัน"
                              :prop="'conditions.' + indexf + '.func'"
                              :rules="{
                                required: true,
                                message: 'เลือกฟังก์ชัน',
                                trigger: ['change'],
                              }"
                            >
                              <el-select
                                :id="`filtersSymbol` + (indexf + 1)"
                                v-model="conditions.func"
                                placeholder="ฟังก์ชัน"
                              >
                                <el-option
                                  v-for="(item, index) in optionsFunction"
                                  :key="index"
                                  :label="item.label"
                                  :value="item.value"
                                >
                                </el-option>
                              </el-select>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item
                              label="ตัวแปร"
                              :prop="'conditions.' + indexf + '.variableName'"
                              :rules="{
                                required: true,
                                message: 'เลือกตัวแปร',
                                trigger: ['change'],
                              }"
                            >
                              <el-select
                                :id="`conditionsVariableName` + (indexf + 1)"
                                filterable
                                v-model="conditions.variableName"
                                placeholder="กรุณาเลือกตัวแปร"
                                no-match-text="ไม่พบข้อมูลที่ค้นหา"
                                @change="setVariableYear('condition', indexf)"
                              >
                                <el-option
                                  v-for="(item, index) in optionsVariable"
                                  :key="index"
                                  :label="item.label"
                                  :value="item.value"
                                >
                                </el-option>
                              </el-select>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="24"
                            v-for="(formula, indexFor) in conditions.formula"
                            :key="indexFor"
                          >
                            <el-row :gutter="10">
                              <el-col :span="6">
                                <el-form-item
                                  label="เครื่องหมาย"
                                  :prop="
                                    'conditions.' +
                                    indexf +
                                    '.formula.' +
                                    indexFor +
                                    '.symbol'
                                  "
                                  :rules="{
                                    required: true,
                                    message: 'เลือกเครื่องหมาย',
                                    trigger: ['change'],
                                  }"
                                >
                                  <el-select
                                    :id="
                                      `filtersSymbol` +
                                      (indexf + 1) +
                                      (indexFor + 1)
                                    "
                                    v-model="formula.symbol"
                                    placeholder="เครื่องหมาย"
                                  >
                                    <el-option
                                      v-for="(item, index) in optionsSymbol"
                                      :key="index"
                                      :label="item.label"
                                      :value="item.value"
                                    >
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </el-col>
                              <el-col :span="12">
                                <el-form-item
                                  label="ตัวแปร"
                                  :prop="
                                    'conditions.' +
                                    indexf +
                                    '.formula.' +
                                    indexFor +
                                    '.variableName'
                                  "
                                  :rules="{
                                    required: true,
                                    message: 'เลือกตัวแปร',
                                    trigger: ['change'],
                                  }"
                                >
                                  <el-select
                                    :id="
                                      `filtersVariableName` +
                                      (indexf + 1) +
                                      (indexFor + 1)
                                    "
                                    filterable
                                    v-model="formula.variableName"
                                    placeholder="กรุณาเลือกตัวแปร"
                                    no-match-text="ไม่พบข้อมูลที่ค้นหา"
                                    @change="
                                      setVariableYear(
                                        'formula',
                                        indexf,
                                        indexFor
                                      )
                                    "
                                  >
                                    <el-option
                                      v-for="(item, index) in optionsVariable"
                                      :key="index"
                                      :label="item.label"
                                      :value="item.value"
                                    >
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </el-col>
                              <el-col :span="6">
                                <el-form-item>
                                  <span slot="label" class="color-ff">ลบ</span>
                                  <el-button
                                    :id="
                                      `deleteVariable` +
                                      (indexf + 1) +
                                      (indexFor + 1)
                                    "
                                    type="danger"
                                    class="w-100"
                                    @click="
                                      delArrVariable(
                                        indexf,
                                        indexFor,
                                        formula._id
                                      )
                                    "
                                    >ลบ</el-button
                                  >
                                </el-form-item>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col :span="24" class="mg-t-0">
                            <span
                              class="color-main font-20 cursor-pointer"
                              @click="addArrVariable(indexf)"
                              ><i class="fas fa-plus"></i> เพิ่มตัวแปร</span
                            >
                          </el-col>
                          <el-col :span="24">
                            <el-row :gutter="10">
                              <el-col :span="6">
                                <el-form-item
                                  label="เงื่อนไข"
                                  :prop="'conditions.' + indexf + '.symbol'"
                                  :rules="{
                                    required: true,
                                    message: 'เลือกเงื่อนไข',
                                    trigger: ['change'],
                                  }"
                                >
                                  <el-select
                                    :id="`symbol${indexf + 1}`"
                                    v-model="conditions.symbol"
                                    placeholder="เงื่อนไข "
                                  >
                                    <el-option
                                      v-for="(item, index) in optionsConditions"
                                      :key="index"
                                      :label="item.label"
                                      :value="item.value"
                                    >
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </el-col>
                              <el-col :span="18">
                                <el-form-item
                                  :prop="'conditions.' + indexf + '.value'"
                                  :rules="{
                                    required: true,
                                    message: 'กรุณากรอกตัวเลข',
                                    trigger: ['blur', 'change'],
                                  }"
                                >
                                  <span slot="label"
                                    >ตัวเลข
                                    <span class="pd-l-7 color-c4"
                                      >(ทศนิยม 2 ตำหน่ง)</span
                                    ></span
                                  >
                                  <el-input-number
                                    :id="`conditionFormValueNumber${
                                      indexf + 1
                                    }`"
                                    controls-position="right"
                                    :precision="2"
                                    :step="0.01"
                                    :min="-90000000000"
                                    :max="90000000000"
                                    v-model="conditions.value"
                                    @keypress.native="isNumber($event)"
                                  >
                                  </el-input-number>
                                </el-form-item>
                              </el-col>
                              <!-- ให้ส่งเป็น 0 ไป -->
                              <!-- <el-col :span="6">
                                <el-form-item
                                  label="หน่วย"
                                  :prop="'conditions.' + indexf + '.type'"
                                  :rules="{
                                    required: true,
                                    message: 'กรุณากรอกหน่วย',
                                    trigger: ['change'],
                                  }"
                                >
                                  <el-select
                                    :id="`conditionFormUnit${indexf + 1}`"
                                    v-model="conditions.type"
                                    placeholder="หน่วย"
                                  >
                                    <el-option
                                      v-for="(item, index) in optionsValue"
                                      :key="index"
                                      :label="item.label"
                                      :value="item.value"
                                    >
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </el-col> -->
                            </el-row>
                          </el-col>
                        </el-row>
                        <el-button
                          :id="`deleteCondition${indexf + 1}`"
                          v-if="conditionForm.conditions.length > 1"
                          class="btn-color3 mg-r-6 mg-l-7 del-con"
                          @click="deleteCondition(indexf, conditions._id)"
                        >
                          <i class="fas fa-trash-alt font-14"></i>
                        </el-button>
                      </div>
                    </el-col>
                    <el-col :span="24">
                      <el-button
                        class="more-condition color-c4 mg-t-5"
                        @click="addFunc"
                      >
                        <i class="fas fa-plus"></i>
                        เพิ่มฟังก์ชัน
                      </el-button>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <!-- <div class="is-flex js-end mg-t-1">
          <el-button
            id="cancleUpdate"
            class="font-16 btn-cancle"
            @click="drawerClose"
            >ยกเลิก</el-button
          >
          <el-button
            id="submitUpdateAll"
            type="primary"
            class="font-16 save-condition"
            @click="submitUpdateAll"
            style="min-width: 120px"
            :loading="loadingDrawer"
            :class="loadingDrawer ? 'cursor-none' : ''"
            :disabled="disabledSave"
            >บันทึก
          </el-button>
        </div> -->
      </div>
    </el-drawer>
    <el-drawer
      class="drawer-criterion"
      :visible.sync="modalTransectionLog"
      :with-header="false"
    >
      <div class="box-header normal">
        <div class="text-center" style="position: relative">
          <span> เก็บประวัติเกณฑ์พิจารณาสินเชื่อ </span>
          <span class="icon-close" @click="modalTransectionLog = false">
            X
          </span>
        </div>
      </div>
      <div id="custom-collapse" v-if="modalTransectionLog">
        <div v-if="arrLog.length > 0">
          <div v-for="(log, indexF) in arrLog" :key="indexF" class="detail-log">
            <p class="font-20">
              <span class="main">{{ log.employee }} </span>
              <span>ได้{{ log.action }}</span>
              <span class="main">{{ log.desc }}</span>
              <span>ในปี</span>
              <span class="main">{{ log.year }}</span>
            </p>
            <p class="font-18">{{ log.logTime | fromNow }}</p>
          </div>
        </div>
        <p class="font-20 color-75 text-center mg-0" v-else>ไม่มีข้อมูล</p>
      </div>
    </el-drawer>
    <el-dialog
      :visible.sync="modalDeleteSpecial"
      width="40%"
      class="modal-criterion"
      :close-on-click-modal="false"
    >
      <div slot="title">
        <p class="color-00 font-24">
          คุณต้องการที่จะลบคุณสมบัติเกณฑ์แบบพิเศษหรือไม่
        </p>
      </div>
      <div class="is-flex js-end mg-t-4">
        <el-button
          class="font-16 btn-cancle"
          @click="modalDeleteSpecial = false"
          >ไม่ต้องการ</el-button
        >
        <el-button
          type="danger"
          class="font-16"
          @click="submitDeleteSpecial"
          :loading="loadingSaveSpecial"
          style="min-width: 120px"
          >ต้องการ</el-button
        >
      </div>
    </el-dialog>
  </DefaultTemplate>
</template>
<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import { HTTP } from "@/service/axios";
import TableGrade from "@/components/criteria/TableGrade";
import TabNameSize from "@/components/criteria/TabNameSize";
import { mapState } from "vuex";
import numeral from "numeral";
import moment from "moment";
import TableUserVue from "../components/user/TableUser.vue";

export default {
  components: {
    DefaultTemplate,
    TableGrade,
    TabNameSize,
  },
  data() {
    var checkPropertyName = (rule, value, callback) => {
      if (this.conditionForm.propertyName == "") {
        return callback(new Error("กรุณากรอกชื่อคุณสมบัติเกณฑ์"));
      } else {
        return callback();
      }
    };
    return {
      statusView: null,
      loadingSaveSpecial: false,
      idDeleteSpecial: null,
      modalDeleteSpecial: false,
      modalTransectionLog: false,
      arrLog: [],
      loadingSaveProperty: false,
      disabledSave: false,
      activeName: "",
      drawerEdit: false,
      idData: this.$route.params.id,
      form: {
        search: "",
      },
      dataRes: [],
      dataRes1: [],
      optionYears: [],
      loading: true,
      modalEditName: false,
      formEdit: {
        criterionName: "",
        criterionYear: "",
      },
      rules: {
        criterionName: [
          {
            required: true,
            message: "กรุณากรอกชื่อเกณฑ์",
            trigger: ["blur", "change"],
          },
        ],
        propertyName: [
          {
            validator: checkPropertyName,
            trigger: ["blur", "change"],
          },
        ],
        variableName: [
          {
            required: true,
            message: "กรุณากรอกชื่อเกณฑ์",
            trigger: ["blur", "change"],
          },
        ],
        condition: [
          {
            required: true,
            message: "กรุณาเลือกเงื่อนไข",
            trigger: "change",
          },
        ],

        value: [
          {
            required: true,
            message: "กรุณากรอกตัวเลข",
            trigger: "blur",
          },
        ],
        unit: [
          {
            required: true,
            message: "กรุณาเลือกหน่วย",
            trigger: "change",
          },
        ],
      },
      tableEditGrades: [],
      loadingDrawer: false,
      conditionTab: false,
      showAddCondotion: true,
      showEditCondotion: false,
      conditionForm: {},
      propertyNameById: "",
      optionsVariable: [],
      optionsFunction: [
        {
          value: 2,
          label: "และ",
        },
        {
          value: 1,
          label: "หรือ",
        },
      ],
      optionsSymbol: [
        {
          value: "+",
          label: "+",
        },
        {
          value: "-",
          label: "-",
        },
        {
          value: "*",
          label: "*",
        },
        {
          value: "/",
          label: "/",
        },
      ],
      optionsConditions: [
        {
          value: ">",
          label: ">",
        },
        {
          value: "<",
          label: "<",
        },
        {
          value: "==",
          label: "=",
        },
        {
          value: ">=",
          label: ">=",
        },
        {
          value: "<=",
          label: "<=",
        },
        {
          value: "!=",
          label: "!=",
        },
      ],
      optionsValue: [
        {
          value: 1,
          label: "บาท",
        },
        {
          value: 2,
          label: "ร้อยละ (%)",
        },
      ],
      variableAll: [],
      loadingEdit: false,
      delFormula: [],
      dataGrades: [],
      activeEdit: 0,
      updateCriteria: false,
      test: [],
      criterionDate: null,
    };
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      user: (state) => state.user,
      permissionNow: (state) => state.permissionNow,
      memberOfOrganize: (state) => state.memberOfOrganize,
    }),
  },
  mounted() {
    this.getCriteriaById();
    this.getYears();
    this.getEnlite();
  },
  created() {
    window.addEventListener(
      "beforeunload",
      () => {
        if (this.activeEdit == 1) {
          return this.sendIsEdit(0, 1);
        }
      },
      false
    );
  },
  async beforeDestroy() {
    // alert('sdfghjasdfghj')
    // await this.sendIsEdit(0,1);
    // console.log('asdfghjksadfghj')
    await this.destroyedEdit();
  },
  methods: {
    dateThai(date) {
      return moment(date).format("DD/MM/") + (moment(date).year() + 543);
    },
    disabledMemberOf(data, option) {
      let result = false;
      if (data.length > 0) {
        var findByName = data.find((e) => e == 7);
        if (typeof findByName == "undefined") {
          if (option == 7) {
            result = true;
          }
        } else {
          if (option != 7) {
            result = true;
          }
        }
      } else {
        result = false;
      }
      return result;
    },
    async destroyedEdit() {
      if (this.activeEdit == 1) {
        await this.sendIsEdit(0, 1);
      }
    },
    checkIsEdit() {
      this.sendIsEdit(1, 1);
      //   // if (
      //   //   this.dataRes.editBy != null &&
      //   //   this.dataRes.editBy.id != this.user.id
      //   // ) {
      //   if (this.dataRes.statusEdit == false && this.dataRes.active == false) {
      //     this.sendIsEdit(true);
      //   }
      //   // else {
      //   //     this.statusView = true;
      //   //   }
      //   // } else if (
      //   //   this.dataRes.editBy == null &&
      //   //   this.dataRes.statusEdit == false &&
      //   //   this.dataRes.active == false
      //   // ) {
      //   //   this.sendIsEdit(true);
      //   // } else {
      //   //   this.statusView = false;
      //   // }
    },
    fetchDataOut() {
      this.tableEditGrades = [];
      this.getCriteriaById();
    },
    getHistoryLog() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`history/criterion/${this.idData}`)
        .then((res) => {
          if (res.data.success) {
            this.arrLog = res.data.obj;
            this.modalTransectionLog = true;
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    handleChange(val) {
      if (val != "special") {
        this.disabledSave = false;
        setTimeout(() => {
          this.showEditCondotion = false;
          this.conditionTab = false;
          this.showAddCondotion = true;
        }, 520);
      } else {
        this.disabledSave = false;
        setTimeout(() => {
          this.conditionTab = false;
          this.showAddCondotion = true;
        }, 500);
      }
    },
    addFunc() {
      this.conditionForm.conditions.push({
        variableName: "",
        variableYear: "",
        func: "",
        formula: [
          {
            symbol: "",
            variableName: "",
            variableYear: "",
          },
        ],
        symbol: "",
        value: "",
        type: 0,
      });
    },
    addSpecial() {
      this.conditionForm = {
        propertyName: "",
        conditions: [
          {
            variableName: "",
            variableYear: "",
            func: "",
            formula: [
              {
                symbol: "",
                variableName: "",
                variableYear: "",
              },
            ],
            symbol: "",
            value: "",
            type: 0,
          },
        ],
      };
      this.showAddCondotion = false;
      this.conditionTab = true;
      this.disabledSave = true;
    },
    getYears() {
      let d = new Date();
      let years = d.getFullYear() - 1;
      for (let index = 0; index < 7; index++) {
        let obj = {
          value: (years + index + 543).toString(),
          label: (years + index + 543).toString(),
        };
        this.optionYears.push(obj);
      }

      this.optionYears.sort((a, b) => {
        let modifier = -1;
        if (a.value < b.value) return -1 * modifier;
        if (a.value > b.value) return 1 * modifier;
        return 0;
      });
    },
    async getEnlite() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.get(`variable/enlite`)
        .then((res) => {
          if (res.data.success) {
            this.variableAll = res.data.obj;
            for (let i = 0; i < res.data.obj.length; i++) {
              let obj = {
                label: res.data.obj[i].value,
                value: res.data.obj[i].value,
              };
              this.optionsVariable.push(obj);
            }
          }
          return res;
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
          return {
            data: {
              success: false,
            },
          };
        });
      return res;
    },
    async getCriteriaById(typeGet) {
      // HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await this.$store.dispatch("criterion/GETBYID", this.idData);
      if (res.success) {
        let criterionDate = moment(res.obj.createdAt);
        this.criterionDate = this.dateThai(criterionDate);
        this.dataRes = res.obj;
        this.dataRes.property.sort((a, b) => {
          let modifier = 1;
          if (a.type < b.type) return -1 * modifier;
          if (a.type > b.type) return 1 * modifier;
          return 0;
        });
        if (this.updateCriteria == false) {
          if (this.dataRes.statusWaite == 1 && this.dataRes.active == false) {
            if (this.user.id == this.dataRes.waiteBy.id) {
              // this.sendIsEdit(false);
              await this.sendIsEdit(0, 1);
              this.dataRes.statusWaite = 0;
            }
          }
        } else {
          this.dataRes.statusWaite = 0;
        }

        this.dataRes1 = res.obj;
        const setArr = this.dataRes1.property.reduce((result, row) => {
          let groupTrueGrade = [];
          let groupGradeNoNon = [];
          row.grade.forEach((getGrade) => {
            if (getGrade.status === true) {
              groupTrueGrade.push(getGrade.gradeName);
            }
            if (getGrade.gradeName != "Non-Prospect") {
              groupGradeNoNon.push(getGrade);
            }
          });
          const obj = {
            ...row,
            statusEdit: false,
            isIndeterminate:
              row.active == false
                ? false
                : groupTrueGrade.length > 0 &&
                  groupTrueGrade.length < row.grade.length - 1,
            checkedList: groupTrueGrade,
          };

          groupGradeNoNon.map(
            (e) => (e.status = row.active == false ? false : e.status)
          );
          obj.grade = groupGradeNoNon;
          if (
            row.type == 0 ||
            (row.type == null && result.indexOf(obj) === -1)
          ) {
            result.push(obj);
          }
          return result;
        }, []);
        const setArr2 = this.dataRes1.propertySpecial.reduce((result, row) => {
          let groupTrueGrade = [];
          let groupGradeNoNon = [];
          row.conditions.sort((a, b) => {
            let modifier = 1;
            if (a._id < b._id) return -1 * modifier;
            if (a._id > b._id) return 1 * modifier;
            return 0;
          });
          row.grade.forEach((getGrade) => {
            if (getGrade.status === true) {
              groupTrueGrade.push(getGrade.gradeName);
            }
            if (getGrade.gradeName != "Non-Prospect") {
              groupGradeNoNon.push(getGrade);
            }
          });
          const obj = {
            ...row,
            statusEdit: false,
            isIndeterminate:
              groupTrueGrade.length > 0 &&
              groupTrueGrade.length < row.grade.length - 1,
            checkedList: groupTrueGrade,
          };

          groupGradeNoNon.map(
            (e) => (e.status = row.active == false ? false : e.status)
          );
          obj.grade = groupGradeNoNon;
          if (row.type == 1 && result.indexOf(obj) === -1) {
            result.push(obj);
          }
          return result;
        }, []);

        let test = {
          ...this.dataRes1,
          property: setArr,
          propertySpecial: setArr2,
        };
        let changeJson = JSON.stringify(test);
        //ถ้า tableEditGrades เป็น array เปล่า ให้แทนค่า
        //ถ้ามี typeGet คือการการไขจาก grade ไม่ต้องเอาไปแทนค่า
        if (this.tableEditGrades.length == 0) {
          this.tableEditGrades = JSON.parse(changeJson);
        } else if (typeof typeGet == "undefined") {
          this.tableEditGrades = JSON.parse(changeJson);
        }
        this.loading = false;
      } else {
        if (res.status == 403) {
          this.$store.commit("SET_PAGE_ACTIVE", null);
          this.$router.push("/not-allowed");
        } else if (res.status == 401) {
          this.checkAuth();
        } else if (res.status == 404 || res.status == 422) {
          this.$message({
            message: "ไม่พบเกณฑ์พิจารณาจัดเกรดสินเชื่อ",
            type: "error",
            duration: 4000,
          });
          this.$router.push(`/criteria-credit`).catch((e) => {});
          return;
        } else if (res.status == 502) {
          console.log("Bad GetWay");
          return;
        } else {
          let message = !!res.data ? res.data : res;
          this.alertCatchError(message);
          return;
        }
      }
      // HTTP.get(`criterion/${this.idData}`)
      //   .then(async (res) => {
      //     if (res.data.success) {
      //       this.dataRes = res.data.obj;
      //       this.dataRes.property.sort((a, b) => {
      //         let modifier = 1;
      //         if (a.type < b.type) return -1 * modifier;
      //         if (a.type > b.type) return 1 * modifier;
      //         return 0;
      //       });
      //       if (this.updateCriteria == false) {
      //         if (
      //           this.dataRes.statusWaite == 1 &&
      //           this.dataRes.active == false
      //         ) {
      //           if (this.user.id == this.dataRes.waiteBy.id) {
      //             // this.sendIsEdit(false);
      //             await this.sendIsEdit(0, 1);
      //             this.dataRes.statusWaite = 0;
      //           }
      //         }
      //       } else {
      //         this.dataRes.statusWaite = 0;
      //       }

      //       this.dataRes1 = res.data.obj;
      //       const setArr = this.dataRes1.property.reduce((result, row) => {
      //         let groupTrueGrade = [];
      //         let groupGradeNoNon = [];
      //         row.grade.forEach((getGrade) => {
      //           if (getGrade.status === true) {
      //             groupTrueGrade.push(getGrade.gradeName);
      //           }
      //           if (getGrade.gradeName != "Non-Prospect") {
      //             groupGradeNoNon.push(getGrade);
      //           }
      //         });
      //         const obj = {
      //           ...row,
      //           statusEdit: false,
      //           isIndeterminate:
      //             row.active == false
      //               ? false
      //               : groupTrueGrade.length > 0 &&
      //                 groupTrueGrade.length < row.grade.length - 1,
      //           checkedList: groupTrueGrade,
      //         };

      //         groupGradeNoNon.map(
      //           (e) => (e.status = row.active == false ? false : e.status)
      //         );
      //         obj.grade = groupGradeNoNon;
      //         if (
      //           row.type == 0 ||
      //           (row.type == null && result.indexOf(obj) === -1)
      //         ) {
      //           result.push(obj);
      //         }
      //         return result;
      //       }, []);
      //       const setArr2 = this.dataRes1.propertySpecial.reduce(
      //         (result, row) => {
      //           let groupTrueGrade = [];
      //           let groupGradeNoNon = [];
      //           row.conditions.sort((a, b) => {
      //             let modifier = 1;
      //             if (a._id < b._id) return -1 * modifier;
      //             if (a._id > b._id) return 1 * modifier;
      //             return 0;
      //           });
      //           row.grade.forEach((getGrade) => {
      //             if (getGrade.status === true) {
      //               groupTrueGrade.push(getGrade.gradeName);
      //             }
      //             if (getGrade.gradeName != "Non-Prospect") {
      //               groupGradeNoNon.push(getGrade);
      //             }
      //           });
      //           const obj = {
      //             ...row,
      //             statusEdit: false,
      //             isIndeterminate:
      //               groupTrueGrade.length > 0 &&
      //               groupTrueGrade.length < row.grade.length - 1,
      //             checkedList: groupTrueGrade,
      //           };

      //           groupGradeNoNon.map(
      //             (e) => (e.status = row.active == false ? false : e.status)
      //           );
      //           obj.grade = groupGradeNoNon;
      //           if (row.type == 1 && result.indexOf(obj) === -1) {
      //             result.push(obj);
      //           }
      //           return result;
      //         },
      //         []
      //       );

      //       let test = {
      //         ...this.dataRes1,
      //         property: setArr,
      //         propertySpecial: setArr2,
      //       };
      //       let changeJson = JSON.stringify(test);
      //       //ถ้า tableEditGrades เป็น array เปล่า ให้แทนค่า
      //       //ถ้ามี typeGet คือการการไขจาก grade ไม่ต้องเอาไปแทนค่า
      //       if (this.tableEditGrades.length == 0) {
      //         this.tableEditGrades = JSON.parse(changeJson);
      //       } else if (typeof typeGet == "undefined") {
      //         this.tableEditGrades = JSON.parse(changeJson);
      //       }
      //       this.loading = false;
      //     } else {
      //       if (res.data.status == 403) {
      //         this.$store.commit("SET_PAGE_ACTIVE", null);
      //         this.$router.push("/not-allowed");
      //       }
      //     }
      //   })
      // .catch((e) => {
      //   if (e.response.status != 502) {
      //     if (e.response.data.status == 401) {
      //       this.checkAuth();
      //     } else if (
      //       e.response.data.status == 404 ||
      //       e.response.data.status == 422
      //     ) {
      //       this.$message({
      //         message: "ไม่พบเกณฑ์พิจารณาจัดเกรดสินเชื่อ",
      //         type: "error",
      //         duration: 4000,
      //       });
      //       this.$router.push(`/criteria-credit`).catch((e) => {});
      //     } else if (e.response.data.status == 502) {
      //       console.log("Bad GetWay");
      //     } else {
      //       this.alertCatchError(e);
      //     }
      //   }
      // });
    },
    // statusWaite 0 ปิด / 1 รอ
    // statusEdit 2 บันทึกสำเร็จ
    async sendIsEdit(statusWaite, statusEdit) {
      let obj = {
        id: this.idData,
        statusWaite: statusWaite,
        waiteBy: this.user.id,
        status: statusEdit,
        editBy: this.user.id,
      };
      this.activeEdit = statusWaite;

      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.post(`criterion/write`, obj);
      return await res;
    },
    async openModalEditName() {
      this.modalEditName = true;
      this.formEdit.criterionName = this.dataRes.criterionName;
      this.formEdit.criterionYear = this.dataRes.criterionYear;
      await this.sendIsEdit(1, 1);
    },
    submitEditCriterion() {
      this.$refs["formCriterionEdit"].validate().then(async (valid) => {
        if (valid) {
          var res = await this.$store.dispatch("criterion/UPDATE", {
            _id: this.idData,
            criterionName: this.formEdit.criterionName,
          });
          if (res.success) {
            this.$message({
              message: "แก้ไขชื่อเกณฑ์พิจารณาจัดเกรดสินเชื่อ สำเร็จ",
              type: "success",
              duration: 4000,
            });
            await this.sendIsEdit(0, 2);
            this.getCriteriaById();
            this.modalEditName = false;
            return;
          } else {
            if (res.status == 204) {
              this.$message({
                message: "ชื่อเกณฑ์พิจารณาจัดเกรดสินเชื่อ ซ้ำ",
                type: "error",
                duration: 4000,
              });
            } else if (res.status == 401) {
              this.checkAuth();
              return;
            } else if (res.status == 502) {
              console.log("Bad GetWay");
              return;
            } else {
              let message = !!res.data ? res.data : res;
              this.alertCatchError(message);
            }
          }
          // let obj = {
          //   criterionName: this.formEdit.criterionName,
          //   criterionYear: this.formEdit.criterionYear,
          // };
          // HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          // HTTP.put(`criterion/${this.idData}`, obj)
          //   .then(async (res) => {
          //     if (res.data.success) {
          //       this.$message({
          //         message: "แก้ไขชื่อเกณฑ์พิจารณาจัดเกรดสินเชื่อ สำเร็จ",
          //         type: "success",
          //         duration: 4000,
          //       });
          //       await this.sendIsEdit(0, 2);
          //       this.getCriteriaById();

          //       this.modalEditName = false;
          //     } else {
          //       if (res.data.status == 204) {
          //         this.$message({
          //           message: "ชื่อเกณฑ์พิจารณาจัดเกรดสินเชื่อ ซ้ำ",
          //           type: "error",
          //           duration: 4000,
          //         });
          //       }
          //     }
          //   })
          //   .catch((e) => {
          //     if (e.response.data.status == 401) {
          //       this.checkAuth();
          //     } else if (e.response.data.status == 502) {
          //       console.log("Bad GetWay");
          //     } else if (e.response.data.status == 502) {
          //       console.log("Bad GetWay");
          //     } else {
          //       this.alertCatchError(e);
          //     }
          //   });
        }
      });
    },
    async openDrawerEdit() {
      this.drawerEdit = true;
      await this.sendIsEdit(1, 1);
      this.activeName = "special";
      this.showAddCondotion = true;
      // await this.checkIsEdit();
    },
    async drawerClose() {
      this.drawerEdit = false;
      this.showEditCondotion = false;
      let header = document.getElementById("custom-collapse");
      let coll = header.getElementsByClassName("collapsible");
      let contentDetail = header.getElementsByClassName("content");

      let coll1 = header.getElementsByClassName("collapsible1");
      let contentDetail1 = header.getElementsByClassName("content1");
      for (let i = 0; i < coll.length; i++) {
        coll[i].classList.remove("active");
        contentDetail[i].style.maxHeight = null;
      }
      for (let i = 0; i < coll1.length; i++) {
        coll1[i].classList.remove("active");
        contentDetail1[i].style.maxHeight = null;
      }
      this.activeName = "";
      this.conditionTab = false;
      this.disabledSave = false;

      this.showAddCondotion = true;
      this.tableEditGrades.property.forEach((property) => {
        property.statusEdit = false;
      });
      if (this.updateCriteria != true) {
        await this.destroyedEdit();
      } else {
        if (this.activeEdit == 1) {
          await this.sendIsEdit(0, 1);
        }
      }
      this.updateCriteria = false;
      // this.$refs["conditionForm"].resetFields();
    },
    openContent(index, type) {
      let header = document.getElementById("custom-collapse");
      let nameByType = "";
      let contentByType = "";
      if (type == "property") {
        nameByType = "collapsible";
        contentByType = "content";
      } else {
        nameByType = "collapsible1";
        contentByType = "content1";
      }
      let coll = header.getElementsByClassName(nameByType);
      let contentDetail = header.getElementsByClassName(contentByType);
      for (let i = 0; i < coll.length; i++) {
        if (i != index) {
          coll[i].classList.remove("active");
          contentDetail[i].style.maxHeight = null;
        } else {
          coll[index].classList.toggle("active");

          if (coll[i].classList.value == nameByType + " active") {
            contentDetail[index].style.maxHeight =
              contentDetail[index].scrollHeight + "px";
          } else {
            contentDetail[i].style.maxHeight = null;
          }
        }
      }
    },
    //กรณีที่ click ข้างใน

    checkAllGrade(index, type) {
      let arrNow = "";
      if (type == "special") {
        arrNow = this.tableEditGrades.propertySpecial[index];
      } else {
        arrNow = this.tableEditGrades.property[index];
      }

      let checkStatus = arrNow.grade.filter((a) => a.status == false);
      if (checkStatus.length > 0) {
        arrNow.active = false;
      } else {
        arrNow.active = true;
      }
    },
    editListProperty(index, type) {
      let arr = this.tableEditGrades.property.findIndex((f) => f == index);
      if (arr < 0) {
        this.tableEditGrades.property[index].statusEdit = true;
        this.tableEditGrades.property[index] =
          this.tableEditGrades.property[index];
        if (type == "save") {
          this.loadingSaveProperty = true;
          let nameVal = "setValue" + index;
          let obj = {
            propertyName: this.returnName(
              this.tableEditGrades.property[index].propertyName,
              index,
              this.tableEditGrades.property[index].val1
            ),
            val1: "",
            val2: "",
          };
          if (
            this.dataRes.property[index].propertyNo == 4 ||
            this.dataRes.property[index].propertyNo == 5 ||
            this.dataRes.property[index].propertyNo == 11 ||
            this.dataRes.property[index].propertyNo == 12 ||
            this.dataRes.property[index].propertyNo == 13 ||
            this.dataRes.property[index].propertyNo == 14 ||
            this.dataRes.property[index].propertyNo == 15
          ) {
            obj.val1 = numeral(document.getElementById(nameVal).value).value();
            obj.val2 = numeral(
              document.getElementById(nameVal + index).value
            ).value();
          } else {
            obj.val1 = numeral(document.getElementById(nameVal).value).value();
            obj.val2 = numeral(document.getElementById(nameVal).value).value();
          }
          if (obj.val1 <= 9999999999999 && obj.val2 <= 9999999999999) {
            if (Number.isInteger(obj.val1) && Number.isInteger(obj.val2)) {
              HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
              HTTP.put(
                `criterion/property/${this.tableEditGrades.property[index]._id}`,
                obj
              )
                .then(async (res) => {
                  if (res.data.success) {
                    this.loadingSaveProperty = false;
                    // let row = this.tableEditGrades.property[index];
                    // let whereGradeStatus = row.grade.filter(
                    //   (e) => e.status == true
                    // );
                    // let objGrade = {
                    //   properties: [
                    //     {
                    //       _id: row._id,
                    //       active: whereGradeStatus.length > 0 ? true : false,
                    //       grade: row.grade.map((item) => ({
                    //         _id: item._id,
                    //         status: item.status,
                    //       })),
                    //     },
                    //   ],
                    //   specialProperties: [],
                    // };
                    // this.submitUpdateByOne(objGrade);

                    await this.sendIsEdit(1, 2);
                    this.updateCriteria = true;
                    this.getCriteriaById();
                    this.$message({
                      message: "แก้ไขเกณฑ์สินเชื่อ สำเร็จ",
                      type: "success",
                      duration: 4000,
                    });
                  }
                })
                .catch((e) => {
                  this.loadingSaveProperty = false;

                  if (e.response.data.status == 401) {
                    this.checkAuth();
                  } else {
                    this.alertCatchError(e);
                  }
                });
            } else {
              this.$message({
                message: "กรุณากรอกข้อมูลเกณฑ์สินเชื่อ",
                type: "error",
                duration: 4000,
              });
            }
          } else {
            this.loadingSaveProperty = false;
            this.$message({
              message: "กรุณากรอกค่าข้อมูลไม่เกิน 9,999,999,999,999",
              type: "error",
              duration: 4000,
            });
          }
        }
      }
    },
    // submitUpdateByOne(data) {
    //   HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
    //   HTTP.post(`criterion/update/${this.idData}`, data).catch((e) => {
    //     if (e.response.data.status == 401) {
    //       this.checkAuth();
    //     } else {
    //       this.alertCatchError(e);
    //     }
    //   });
    // },
    submitUpdateAll(arrNow, type) {
      this.loadingDrawer = true;
      let obj = {
        properties: [],
        specialProperties: [],
      };
      if (type == "normal") {
        obj.properties = arrNow.reduce((result, row) => {
          let whereGradeStatus = row.grade.filter((e) => e.status == true);
          result.push({
            _id: row._id,
            active: whereGradeStatus.length > 0 ? true : false,
            grade: row.grade.map((item) => ({
              _id: item._id,
              status: item.status,
            })),
          });
          return result;
        }, []);
      } else if (type == "special") {
        obj.specialProperties = arrNow.reduce((result, row) => {
          let whereGradeStatus = row.grade.filter((e) => e.status == true);
          result.push({
            _id: row._id,
            active: whereGradeStatus.length > 0 ? true : false,
            grade: row.grade.map((item) => ({
              _id: item._id,
              status: item.status,
            })),
          });
          return result;
        }, []);
      }
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`criterion/update/${this.idData}`, obj)
        .then(async (res) => {
          if (res.data.success) {
            let debounce = null;
            clearTimeout(debounce);
            debounce = setTimeout(() => {
              this.loadingDrawer = false;
            }, 600);
            this.updateCriteria = true;
            await this.sendIsEdit(1, 2);
            this.getCriteriaById("updateCriteria");
            // this.drawerClose();
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    returnName(name, index, val1) {
      let txt = name;
      let formatComma = numeral(this.dataRes.property[index].val1).format(
        "0,0"
      );
      let atVal1 = txt.search(formatComma);
      let one = txt.substring(0, atVal1);
      let two = "";
      let input1 = "";
      let input2 = "";
      if (
        this.dataRes.property[index].propertyNo == 4 ||
        this.dataRes.property[index].propertyNo == 5 ||
        this.dataRes.property[index].propertyNo == 11 ||
        this.dataRes.property[index].propertyNo == 12 ||
        this.dataRes.property[index].propertyNo == 13 ||
        this.dataRes.property[index].propertyNo == 14 ||
        this.dataRes.property[index].propertyNo == 15
      ) {
        input1 = numeral(
          document.getElementById("setValue" + index).value
        ).value();
        input2 = numeral(
          document.getElementById("setValue" + index + index).value
        ).value();
        if (this.dataRes.property[index].propertyNo == 11) {
          return (
            "รายได้จากการดำเนินการ " +
            numeral(input1).format("0,0") +
            " - " +
            numeral(input2).format("0,0") +
            " บาท"
          );
        } else if (this.dataRes.property[index].propertyNo == 12) {
          return (
            "กำไรสุทธิ " +
            numeral(input1).format("0,0") +
            " - " +
            numeral(input2).format("0,0") +
            " บาท"
          );
        } else {
          return (
            "กำไร " +
            numeral(input1).format("0,0") +
            " ใน " +
            numeral(input2).format("0,0") +
            " ปี"
          );
        }
      } else {
        two = txt.substring(atVal1 + formatComma.length, txt.length);
        input1 = numeral(
          document.getElementById("setValue" + index).value
        ).value();
        return one + numeral(input1).format("0,0") + two;
      }
    },
    setStringTooltip(arr) {
      let dataString = "";
      let dataFormula = "";
      let dataUnit = "";
      // for (let i = 0; i < arr.formula.length; i++) {
      //   dataFormula +=
      //     arr.formula[i].symbol + " " + arr.formula[i].variableName + " ";
      // }
      if (arr.unit == 1) {
        dataUnit = "ตัวเลข";
      } else if (arr.unit == 2) {
        dataUnit = "ร้อยละ (%)";
      } else {
        dataUnit = "";
      }
      dataString =
        arr.propertyName +
        " " +
        arr.variableName +
        " " +
        dataFormula +
        arr.condition +
        " " +
        Number(arr.value).toLocaleString() +
        " " +
        dataUnit;
      return dataString;
    },
    addArrVariable(index) {
      this.conditionForm.conditions[index].formula.push({
        symbol: "",
        variableName: "",
        variableYear: "",
      });
    },
    delArrVariable(indexCondition, indexFormula, formulaId) {
      this.conditionForm.conditions[indexCondition].formula.splice(
        indexFormula,
        1
      );
      if (typeof formulaId != "undefined") {
        this.conditionForm.delForm.push(formulaId);
      }
    },
    setVariableYear(type, indexCondition, indexFormula) {
      let formula = [];
      if (type == "condition") {
        formula = this.conditionForm.conditions[indexCondition];
      } else if (type == "formula") {
        formula =
          this.conditionForm.conditions[indexCondition].formula[indexFormula];
      }
      let getOption = this.variableAll.find(
        (e) => e.value == formula.variableName
      );
      formula.variableYear = getOption.year;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode != 189
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    funcResetFields(name) {
      this.$refs[name].resetFields();
    },
    async alignData() {
      let changeJson = JSON.stringify(this.conditionForm);
      let setArrCon = JSON.parse(changeJson);

      setArrCon.conditions.map(async (con, indexC) => {
        if (indexC == 0) {
          con.func = 0;
        }
        con.variableName = this.returnVarName(con.variableName);
        con.formula.forEach((formu) => {
          let getVarCon = this.variableAll.find(
            (e) => e.value == formu.variableName
          );
          formu.variableName = getVarCon.description;
        });
      });
      await this.apiSaveSpecial(setArrCon);
    },
    async apiSaveSpecial(setArrCon) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      console.log("\n\npayload: ", setArrCon);
      let res = await HTTP.post(`criterion/special/${this.idData}`, setArrCon)
        .then(async (res) => {
          if (res.data.success) {
            this.loadingEdit = false;
            this.disabledSave = false;
            this.conditionTab = false;
            await this.sendIsEdit(1, 2);
            this.updateCriteria = true;
            this.getCriteriaById();
            this.$message({
              message: "เพิ่มคุณสมบัติเกณฑ์แบบพิเศษ สำเร็จ",
              type: "success",
              duration: 4000,
            });

            this.showEditCondotion = false;
            this.showAddCondotion = true;
          } else {
            if (res.data.status == 204) {
              this.$message({
                message: "ชื่อคุณสมบัติเกณฑ์แบบพิเศษ ซ้ำ",
                type: "error",
                duration: 4000,
              });
            }
          }
          return res;
        })
        .catch((e) => {
          if (typeof e.response.data != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
          return e;
        })
        .finally(() => {
          this.loadingEdit = false;
        });
      return res;
    },
    savePropertySpecial() {
      this.$refs["conditionForm1"].validate().then(async (valid) => {
        if (valid) {
          this.loadingEdit = true;
          await this.alignData();
          //  await this.funcResetFields("conditionForm1");
        }
      });
    },
    async alignEditData() {
      let setCondition = [];
      let setFormula = [];
      await this.conditionForm.conditions.forEach(async (e, indexC) => {
        // e.formula.forEach(async (f, index) => {
        //   setFormula.push({
        //     _id: f._id,
        //     symbol: f.symbol,
        //     variableName: await this.returnVarName(f.variableName),
        //     variableYear: f.variableYear,
        //   });
        //   if (typeof f._id == "undefined") {
        //     delete setFormula[index]._id;
        //   }
        // });
        await setCondition.push({
          _id: indexC > 0 && typeof e._id == "undefined" ? 0 : e._id,
          variableName: this.returnVarName(e.variableName),
          variableYear: e.variableYear,
          func: indexC == 0 ? 0 : e.func,
          formula: await this.returnFormula(e.formula),
          symbol: e.symbol,
          value: e.value,
          type: e.type,
        });
      });

      let obj = {
        propertyName: this.conditionForm.propertyName,
        delCond: this.conditionForm.delCond,
        delForm: this.conditionForm.delForm,
        conditions: await setCondition,
      };
      await this.apiEditSpecial(obj);
    },
    async apiEditSpecial(obj) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.put(
        `criterion/special/${this.conditionForm._id}`,
        obj
      )
        .then(async (res) => {
          if (res.data.success) {
            this.conditionTab = false;
            this.disabledSave = false;
            await this.sendIsEdit(1, 2);
            this.updateCriteria = true;
            this.getCriteriaById();
            this.$message({
              message: "แก้ไขคุณสมบัติเกณฑ์แบบพิเศษ สำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.showEditCondotion = false;
            this.showAddCondotion = true;
          } else {
            if (res.data.status == 204) {
              this.$message({
                message: "ชื่อคุณสมบัติเกณฑ์แบบพิเศษ ซ้ำ",
                type: "error",
                duration: 4000,
              });
            }
          }
          return res;
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
          return e;
        });
      return res;
    },
    async UpdatePropertySpecial() {
      this.$refs["conditionForm1"].validate().then(async (valid) => {
        if (valid) {
          await this.alignEditData();
          // this.$refs["conditionForm1"].resetFields();
        }
      });
    },
    async returnFormula(formula) {
      let setFormula = [];
      formula.forEach(async (f, index) => {
        setFormula.push({
          _id: f._id,
          symbol: f.symbol,
          variableName: this.returnVarName(f.variableName),
          variableYear: f.variableYear,
        });
        if (typeof f._id == "undefined") {
          delete setFormula[index]._id;
        }
      });
      return setFormula;
    },
    returnVarName(name) {
      let getVarCon = this.variableAll.filter((e) => e.value == name);
      if (getVarCon.length > 0) {
        return getVarCon[0].description;
      }
      return "";
    },
    deleteCondition(indexCondition, id) {
      if (typeof id == "undefined") {
        this.conditionForm.conditions.splice(indexCondition, 1);
      } else {
        this.conditionForm.delCond.push(id);
        this.conditionForm.conditions.splice(indexCondition, 1);
      }
    },
    editPropertySpecial(arr) {
      this.propertyNameById = arr.propertyName;
      this.loadingEdit = true;

      let obj = {
        _id: arr._id,
        propertyName: arr.propertyName,
        conditions: arr.conditions,
        delCond: [],
        delForm: [],
      };

      this.conditionForm = obj;
      this.disabledSave = true;
      setTimeout(() => {
        this.showAddCondotion = false;
        this.showEditCondotion = true;
        this.conditionTab = true;
        this.loadingEdit = false;
      }, 80);
    },
    checkPermissionMore() {
      let ifPermission = this.permissionNow.some(
        (e) => e.pemNum == this.$route.meta.permission && e.status == 2
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    trimInputValue(val) {
      this.formEdit.criterionName = val.trim();
    },

    handleCheckAllChange(index, type) {
      let arrNow = "";
      let gradeOption = [];

      if (type == "special") {
        arrNow = this.tableEditGrades.propertySpecial[index];
      } else {
        arrNow = this.tableEditGrades.property[index];
      }
      arrNow.grade.forEach((e) => gradeOption.push(e.gradeName));
      arrNow.checkedList = arrNow.active ? gradeOption : [];
      arrNow.isIndeterminate = false;
      if (arrNow.active) {
        arrNow.grade.map((d) => {
          if (d.gradeName != "Non-Prospect") {
            d.status = true;
          }
        });
      } else {
        arrNow.grade.map((d) => {
          if (d.gradeName != "Non-Prospect") {
            d.status = false;
          }
        });
      }
      let sendData = [];
      sendData.push(arrNow);
      this.submitUpdateAll(sendData, type);
    },
    modalSpecial(special) {
      this.modalDeleteSpecial = true;
      this.idDeleteSpecial = special._id;
    },
    submitDeleteSpecial() {
      this.loadingSaveSpecial = true;
      this.modalDeleteSpecial = false;

      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.delete(`criterion/special/${this.idDeleteSpecial}`)
        .then(async (res) => {
          if (res.data.status) {
            this.modalDeleteSpecial = false;
            await this.sendIsEdit(1, 2);
            this.updateCriteria = true;
            this.getCriteriaById();
            this.$message({
              message: "ลบคุณสมบัติเกณฑ์แบบพิเศษ สำเร็จ",
              type: "success",
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        })
        .finally(() => {
          this.loadingSaveSpecial = false;
        });
    },
    handleCheckedAllGrade(selectArray, indexArr, type) {
      let checkedCount = selectArray.length;
      let arrNow = [];
      if (type == "normal") {
        arrNow = this.tableEditGrades.property[indexArr];
      } else if (type == "special") {
        arrNow = this.tableEditGrades.propertySpecial[indexArr];
      }
      arrNow.active = checkedCount === arrNow.grade.length;
      arrNow.isIndeterminate =
        checkedCount > 0 && checkedCount < arrNow.grade.length;
      let sendData = [];
      sendData.push(arrNow);
      this.submitUpdateAll(sendData, type);
    },
    async handleClose(modal) {
      if (modal == "modalEditName" && this.modalEditName == true) {
        this.modalEditName = false;
      }
      await this.destroyedEdit();
    },
    async editMemberOf(data) {
      let obj = {
        criterionPropertyId: data._id,
        memberOf: data.memberOf,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`criterion/memberOf`, obj)
        .then(async (res) => {
          if (res.data.status) {
            await this.sendIsEdit(1, 2);
            this.updateCriteria = true;
            this.getCriteriaById();
            this.$message({
              message: "แก้ไขเกณฑ์สินเชื่อ สำเร็จ",
              type: "success",
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        })
        .finally(() => {
          this.loadingSaveSpecial = false;
        });
    },
  },
};
</script>
